import "./ads_index.css";

import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default function AdsIndex({ pageContext: { ads } }) {
    return (
        <Layout>
            <SEO title="Home" />
            <div className="adsIndex">
                {ads.map((ad) => (
                    <div key={ad.id} className="adsIndex-ad">
                        <Link className="adsIndex-ad-title" to={`/ad/${ad.id}`}>
                            {ad.title}
                        </Link>
                    </div>
                ))}
            </div>
        </Layout>
    );
}
